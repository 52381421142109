import { get, filter, find, isEqual, isNumber } from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import {
  AUTHOR_INFO_TYPE_MOBILE_PATH,
  AUTHOR_INFO_TYPE_PATH,
  getGenericPostFontPath,
  getIsSocialSharingProviderEnabledParamPath,
  getPostFontSizesPath,
  getSBSLayoutImagePositionParamPath,
  getLayoutPostSizeParamPath,
  getLayoutSpacingParamPath,
  getLayoutContentHeightParamPath,
  getLayoutContentHeightTypeParamPath,
  getLayoutImageRatioParamPath,
  getLayoutSidesPaddingParamPath,
  getLayoutMarginsParamPath,
  getLayoutTitleLineCountParamPath,
  getLayoutDescriptionLineCountParamPath,
  getLayoutImageProportionsParamPath,
  getLayoutImageAlignmentParamPath,
  getFeedBorderColorPath,
  getMobileFeedBorderColorPath,
  getLayoutCardsRowTypeParamPath,
  getLayoutPostsPerRowParamPath,
  IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
  IS_AUTHOR_NAME_ENABLED_PATH,
  IS_BANNER_ENABLED_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
  IS_BLOG_MENU_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_ENABLED_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_PATH,
  IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_CATEGORY_LABELS_ENABLED_PATH,
  IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
  IS_COMMENT_COUNT_ENABLED_PATH,
  IS_COMMENTS_ENABLED_MOBILE_PATH,
  IS_COMMENTS_ENABLED_PATH,
  IS_FEED_DESIGN_PROPS_MIGRATED_PATH,
  IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
  IS_LIKE_COUNT_ENABLED_PATH,
  IS_PINTEREST_ENABLED_PATH,
  IS_TWITTER_ENABLED_PATH,
  feedWidgetStyleParams,
  IS_POST_DATE_ENABLED_MOBILE_PATH,
  IS_POST_DATE_ENABLED_PATH,
  IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
  IS_POST_DESCRIPTION_ENABLED_PATH,
  IS_POST_LIST_FULL_WIDTH_ENABLED_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_PATH,
  IS_POST_TITLE_ENABLED_MOBILE_PATH,
  IS_POST_TITLE_ENABLED_PATH,
  IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
  IS_POST_UPDATED_DATE_ENABLED_PATH,
  IS_READING_TIME_ENABLED_MOBILE_PATH,
  IS_READING_TIME_ENABLED_PATH,
  IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
  IS_RECENT_POSTS_ENABLED_PATH,
  IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
  IS_SOCIAL_SHARING_ENABLED_PATH,
  IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
  IS_VIEW_COUNT_ENABLED_PATH,
  POST_TITLE_FONT_SIZE_MOBILE_PATH,
  SECTION_CATEGORY,
  IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
  IS_AUTHOR_PICTURE_ENABLED_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_PATH,
  IS_TAGS_ENABLED_PATH,
  IS_TAGS_ENABLED_MOBILE_PATH,
  getLayoutImageCropTypeParamPath,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
  IS_TAG_LABEL_ENABLED_PATH,
  TAG_LAYOUT_PATH,
  TAG_DISPLAY_PARAMS,
  getTagLayoutParams,
  getLayoutTypeById,
  SideBySideLayout,
  isLayoutNamePGSideBySide,
  isLayoutNamePGOneColumn,
  IS_BANNER_ENABLED_DEFAULT,
  getDefaultFontSize,
  getMaxFontSize,
  getMinFontSize,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO,
  DEFAULT_PG_LAYOUT_ITEM_SIZE,
  DEFAULT_PG_LAYOUT_INFO_SIZE,
  DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE,
  DEFAULT_PG_LAYOUT_SPACING,
  DEFAULT_PG_LAYOUT_SIDES_PADDING,
  DEFAULT_PG_LAYOUT_MARGINS,
  DEFAULT_PG_IMAGE_ALIGNMENT,
  DEFAULT_PG_IMAGE_PROPORTIONS,
  DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE,
  getPGLayoutSettingsConstant,
  SOCIAL_SHARING_PROVIDERS,
  RELATED_POSTS_LABEL_OPTIONS,
  RELATED_POSTS_LABEL_STRING_KEY,
  RELATED_POSTS_LABEL_PATH,
  RELATED_POSTS_LABEL_MOBILE_PATH,
  IS_RELATED_POSTS_ENABLED_PATH,
  IS_RELATED_POSTS_ENABLED_MOBILE_PATH,
  IS_RELATED_POSTS_LABEL_ENABLED_MOBILE_PATH,
  IS_RELATED_POSTS_LABEL_ENABLED_PATH,
  IS_CATEGORY_HEADER_ENABLED_PATH,
  IS_CONFIGURABLE_LINE_CLAMPING_ENABLED_PATH,
  DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT,
  DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT,
  TAG_CLOUD_MOBILE_PARAMS,
  TAG_DISPLAY_PARAMS_MOBILE,
  TAG_LAYOUT_MOBILE_PATH,
  IS_CATEGORY_LABEL_ENABLED_PATH,
  IS_CATEGORY_LABEL_ENABLED_MOBILE_PATH,
  DEFAULT_PG_LAYOUT_SPACING_MOBILE,
  DEFAULT_PG_LAYOUT_MARGINS_MOBILE,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE,
  getFeedMetadataSettingsDefaultValue,
  SECTION_MY_POSTS,
  IS_COMMENT_COUNT_ENABLED,
  IS_LIKE_COUNT_ENABLED,
  IS_CATEGORY_LABELS_ENABLED,
  IS_TAGS_ENABLED,
  IS_POST_TITLE_ENABLED,
  IS_POST_DESCRIPTION_ENABLED,
  IS_MORE_OPTIONS_MENU_ENABLED,
  IS_VIEW_COUNT_ENABLED,
  IS_AUTHOR_PICTURE_ENABLED,
  IS_POST_DATE_ENABLED,
  IS_POST_UPDATED_DATE_ENABLED,
  IS_CATEGORY_LABEL_ENABLED,
  IS_AUTHOR_NAME_ENABLED,
  AUTHOR_INFO_TYPE,
  IS_POST_PUBLISH_DATE_ENABLED,
  SOCIAL_SHARING_PRINT,
  IS_SEE_ALL_POSTS_LINK_ENABLED_MOBILE_PATH,
  IS_SEE_ALL_POSTS_LINK_ENABLED_PATH,
  MY_POSTS_PAGE_BORDER_WIDTH,
  MY_POSTS_PAGE_BORDER_WIDTH_DEFAULT_VALUE,
  DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE,
  DEFAULT_PG_LAYOUT_POSTS_PER_ROW,
  IS_POST_DESIGN_IN_FULL_POST_ENABLED_PATH,
  DEFAULT_PG_IMAGE_RESIZING_MODE,
  DEFAULT_PG_IMAGE_WIDTH,
  getLayoutImageResizingModeParamPath,
  getLayoutImageWidthParamPath,
  getLayoutName,
  getPostLayoutParamByName,
  IS_POST_RATING_ENABLED,
  IS_POST_RATING_ENABLED_PATH,
  IS_POST_RATING_ENABLED_MOBILE_PATH,
  getTPASettingsIsRatingsEnabled,
  type Section,
  type MixedLayoutType,
  TagLayout,
  type FeedMetadataParam,
  type ContentType,
  type LayoutSettingsType,
  type PostAction,
  type LayoutName,
  ContentAlignment,
  type TagOrder,
  type LayoutSettingsConstantsKey,
} from '@wix/communities-blog-client-common';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import postPageStyleParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { DEFAULT_POST_TITLE_FONT_SIZE_MOBILE } from '../../post-page/constants/post-page';
import { getPostActions } from '../services/post-actions';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';
import { type AppState, type NormalizedPost } from '../types';
import {
  getAppSettings,
  getAppSettingsFont,
  getAppSettingsNumber,
  getAppSettingsValue,
} from './app-settings-base-selectors';
import { getLayoutType } from './layout-selectors';

import {
  getIsMobileDisplaySettingsEnabled,
  getIsMobileSettingEnabled,
  getIsMobileLayoutSettingsEnabled,
  getIsMobileDesignSettingsEnabled,
} from './mobile-settings-selectors';
import { getSection } from './section-selectors';

export const getLastUpdatedDate = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: 'updatedAt',
    fallback: new Date().getTime(),
  });

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getColorPresets = createDeepEqualSelector([getAppSettings], (appSettings) =>
  get(appSettings, 'colorPresets', []),
);

export const getUseMobileDisplaySettings = (state: AppState) =>
  getIsMobile(state) && getIsMobileDisplaySettingsEnabled(state);
export const getUseMobileLayoutSettings = (state: AppState) =>
  getIsMobile(state) && getIsMobileLayoutSettingsEnabled(state);
export const getUseMobileDesignSettings = (state: AppState) =>
  getIsMobile(state) && getIsMobileDesignSettingsEnabled(state);

type ParamsWithFallback<T> = {
  state: AppState;
  key: `style.booleans.${string}` | `style.numbers.${string}`;
  mobileKey: `style.booleans.${string}` | `style.numbers.${string}`;
  fallback?: T;
};

type ParamsWithFallbackParam = {
  state: AppState;
  wixParam: FeedMetadataParam;
  key: `style.booleans.${string}` | `style.numbers.${string}`;
  mobileKey: `style.booleans.${string}` | `style.numbers.${string}`;
};
function getDisplayToggle<T = boolean>(args: ParamsWithFallback<T>): T;
function getDisplayToggle(args: ParamsWithFallbackParam): boolean | (0 | 1);
function getDisplayToggle<T = boolean>(
  args: ParamsWithFallback<T> & {
    wixParam: ParamsWithFallbackParam['wixParam'];
  },
): boolean | (0 | 1);
function getDisplayToggle<T = boolean>(
  args:
    | ParamsWithFallback<T>
    | ParamsWithFallbackParam
    | (ParamsWithFallback<T> & {
        wixParam: ParamsWithFallbackParam['wixParam'];
      }),
) {
  let fallback = 'fallback' in args ? args.fallback : undefined;

  if ('wixParam' in args) {
    fallback ??= getFeedMetadataSettingsDefaultValue(args.wixParam, getSection(args.state)) as any;
  }

  return getAppSettingsValue({
    state: args.state,
    key: (getUseMobileDisplaySettings(args.state) ? args.mobileKey : args.key) as any,
    fallback,
  }) as boolean | (0 | 1);
}

export const getIsCommentCountEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
    key: IS_COMMENT_COUNT_ENABLED_PATH,
    wixParam: IS_COMMENT_COUNT_ENABLED,
  }) as boolean;

export const getIsLikeCountEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
    key: IS_LIKE_COUNT_ENABLED_PATH,
    wixParam: IS_LIKE_COUNT_ENABLED,
  }) as boolean;

export const getIsCategoryLabelsEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_CATEGORY_LABELS_ENABLED_PATH,
    wixParam: IS_CATEGORY_LABELS_ENABLED,
  }) as boolean;

export const getIsTagsEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_TAGS_ENABLED_MOBILE_PATH,
    key: IS_TAGS_ENABLED_PATH,
    wixParam: IS_TAGS_ENABLED,
  }) as boolean;

export const getIsMoreButtonEnabled = (state: AppState, showMoreButton: boolean) =>
  getIsMoreOptionsMenuEnabled(state) ? showMoreButton : false;

export const getIsPostTitleEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_TITLE_ENABLED_MOBILE_PATH,
    key: IS_POST_TITLE_ENABLED_PATH,
    wixParam: IS_POST_TITLE_ENABLED,
  }) as boolean;

export const getIsPostRatingEnabled = (state: AppState) => {
  const isRatingsEnabled = getTPASettingsIsRatingsEnabled(state);
  return isRatingsEnabled
    ? (getDisplayToggle({
        state,
        mobileKey: IS_POST_RATING_ENABLED_MOBILE_PATH,
        key: IS_POST_RATING_ENABLED_PATH,
        wixParam: IS_POST_RATING_ENABLED,
        fallback: true,
      }) as boolean)
    : false;
};
export const getIsPostDescriptionEnabled = (state: AppState, section?: Section | undefined) => {
  const mobileDisplaySettingsEnabled = getIsMobileDisplaySettingsEnabled(state);
  const isMobile = getIsMobile(state);
  section = section || getSection(state);

  if (section === SECTION_MY_POSTS) {
    return getFeedMetadataSettingsDefaultValue(
      IS_POST_DESCRIPTION_ENABLED,
      SECTION_MY_POSTS,
    ) as boolean;
  }

  if (
    section !== SECTION_POST_LIST &&
    section !== SECTION_RELATED_POSTS &&
    !mobileDisplaySettingsEnabled &&
    isMobile
  ) {
    return true;
  }

  return getDisplayToggle({
    state,
    mobileKey: IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
    key: IS_POST_DESCRIPTION_ENABLED_PATH,
    wixParam: IS_POST_DESCRIPTION_ENABLED,
  }) as boolean;
};

export const getIsMoreOptionsMenuEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
    key: IS_MORE_OPTIONS_MENU_ENABLED_PATH,
    wixParam: IS_MORE_OPTIONS_MENU_ENABLED,
  }) as boolean;

export const getIsViewCountEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
    key: IS_VIEW_COUNT_ENABLED_PATH,
    wixParam: IS_VIEW_COUNT_ENABLED,
  }) as boolean;

export const getIsAuthorNameEnabled = (state: AppState) => {
  const fallback = getFeedMetadataSettingsDefaultValue(
    IS_AUTHOR_NAME_ENABLED,
    getSection(state),
  ) as boolean;
  const useMobileSettings = getUseMobileDisplaySettings(state);

  return getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_NAME_ENABLED_PATH,
    fallback: useMobileSettings
      ? getAppSettingsValue({
          state,
          key: IS_AUTHOR_NAME_ENABLED_PATH,
          fallback,
        })
      : fallback,
  }) as boolean;
};

export const getIsAuthorPictureEnabled = (state: AppState, fallback: boolean | undefined) =>
  getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_PICTURE_ENABLED_PATH,
    wixParam: IS_AUTHOR_PICTURE_ENABLED,
    fallback,
  }) as boolean;

export const getAuthorInfoType = (state: AppState) => {
  const fallback = getFeedMetadataSettingsDefaultValue(
    AUTHOR_INFO_TYPE,
    getSection(state),
  ) as number;

  return getDisplayToggle({
    state,
    key: AUTHOR_INFO_TYPE_PATH,
    mobileKey: AUTHOR_INFO_TYPE_MOBILE_PATH,
    fallback: getUseMobileDisplaySettings(state)
      ? (getAppSettingsValue({
          state,
          key: AUTHOR_INFO_TYPE_PATH,
          fallback,
        }) as number)
      : fallback,
  });
};

const getIsPostDateEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_DATE_ENABLED_PATH,
    wixParam: IS_POST_DATE_ENABLED,
  }) as boolean;

export const getIsPostUpdatedDateEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_UPDATED_DATE_ENABLED_PATH,
    wixParam: IS_POST_UPDATED_DATE_ENABLED,
  }) as boolean;

export const getIsPostListFullWidthEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_POST_LIST_FULL_WIDTH_ENABLED_PATH,
    fallback: false,
  });

export const getIsFeedDesignPropsMigrated = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_FEED_DESIGN_PROPS_MIGRATED_PATH,
    fallback: false,
  });

export const getIsPostPublishDateEnabled = (state: AppState) => {
  const fallback =
    getSection(state) === SECTION_MY_POSTS
      ? (getFeedMetadataSettingsDefaultValue(
          IS_POST_PUBLISH_DATE_ENABLED,
          SECTION_MY_POSTS,
        ) as boolean)
      : getIsPostDateEnabled(state);

  return getDisplayToggle({
    state,
    mobileKey: IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_PUBLISH_DATE_ENABLED_PATH,
    fallback,
  }) as boolean;
};

export const getIsReadingTimeEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_READING_TIME_ENABLED_MOBILE_PATH,
    key: IS_READING_TIME_ENABLED_PATH,
    fallback: getIsPostDateEnabled(state),
  }) as boolean;

/** Consume via `useBlogMenuSettings` */
export const getIsBlogMenuEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_ENABLED_PATH,
    fallback: true,
  });

/** Consume via `useBlogMenuSettings` */
export const getIsBlogMenuCategoryLabelsEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
    fallback: true,
  });

/** Consume via `useBlogMenuSettings` */
export const getIsBlogMenuSearchEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_SEARCH_ENABLED_PATH,
    fallback: true,
  });

/** Consume via `useBlogMenuSettings` */
export const getIsBlogMenuLoginButtonEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
    fallback: !getIsMemberAreaInstalled(state),
  });

export const getIsRelatedPostsEnabled = (state: AppState) => {
  const value = isExperimentEnabled(state, OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)
    ? getDisplayToggle({
        state,
        mobileKey: `style.booleans.${postPageStyleParams.showRelatedPosts.key}`,
        key: `style.booleans.${postPageStyleParams.showRelatedPosts.key}`,
      }) ??
      getDisplayToggle({
        state,
        mobileKey: IS_RELATED_POSTS_ENABLED_MOBILE_PATH,
        key: IS_RELATED_POSTS_ENABLED_PATH,
      })
    : getDisplayToggle({
        state,
        mobileKey: IS_RELATED_POSTS_ENABLED_MOBILE_PATH,
        key: IS_RELATED_POSTS_ENABLED_PATH,
      });

  if (value === undefined) {
    /** @deprecated Legacy param fallback (replaced by `blog-isRelatedPostsEnabled`) */
    return getDisplayToggle({
      state,
      mobileKey: IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
      key: IS_RECENT_POSTS_ENABLED_PATH,
      fallback: true,
    });
  }

  return value;
};

/** @deprecated Consume via `usePostPageSettings` */
export const getIsSeeAllPostsLinkEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_SEE_ALL_POSTS_LINK_ENABLED_MOBILE_PATH,
    key: IS_SEE_ALL_POSTS_LINK_ENABLED_PATH,
  });

/** @deprecated Consume via `usePostPageSettings` */
export const getRelatedPostsLabel = (state: AppState) => {
  const isLabelEnabled = getDisplayToggle({
    state,
    mobileKey: IS_RELATED_POSTS_LABEL_ENABLED_MOBILE_PATH,
    key: IS_RELATED_POSTS_LABEL_ENABLED_PATH,
    fallback: true,
  });
  const labelOption = getAppSettingsValue({
    state,
    key: getUseMobileDisplaySettings(state)
      ? RELATED_POSTS_LABEL_MOBILE_PATH
      : RELATED_POSTS_LABEL_PATH,
    fallback: RELATED_POSTS_LABEL_OPTIONS.recent,
  }) as 0 | 1;

  return isLabelEnabled ? RELATED_POSTS_LABEL_STRING_KEY[labelOption] : undefined;
};

export const getIsCommentsEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENTS_ENABLED_MOBILE_PATH,
    key: IS_COMMENTS_ENABLED_PATH,
    fallback: true,
  });

/** @deprecated Use `usePostPageShareProviders` instead */
export const getIsSocialSharingEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    mobileKey: IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
    key: IS_SOCIAL_SHARING_ENABLED_PATH,
    fallback: true,
  });

/** @deprecated Use `usePostPageShareProviders` instead */
export const getIsPrintEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: getIsSocialSharingProviderEnabledParamPath(
      SOCIAL_SHARING_PRINT,
      getUseMobileDisplaySettings(state),
    ),
    fallback: false,
  });

const getIsSocialSharingProviderEnabled = (state: AppState, provider: string) =>
  getAppSettingsValue({
    state,
    key: getIsSocialSharingProviderEnabledParamPath(provider, getUseMobileDisplaySettings(state)),
    fallback: true,
  });

const getEnabledProviders = (state: AppState) =>
  filter(SOCIAL_SHARING_PROVIDERS, (provider) =>
    getIsSocialSharingProviderEnabled(state, provider),
  );

/** @deprecated Use `usePostPageShareProviders` instead */
export const getSocialSharingProviders = createDeepEqualSelector(
  [getIsSocialSharingEnabled, getEnabledProviders],
  (enabled, providers) => (enabled ? providers : []),
);

export const getPostTitleFontSizeMobile = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: POST_TITLE_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_TITLE_FONT_SIZE_MOBILE,
  });

const getDesktopFontSize = (state: AppState, section: Section, font: string) =>
  getAppSettingsValue({
    state,
    key: `${getGenericPostFontPath(section, font)}`,
  })?.size;

export const getDefaultFontSizeDesktop = (
  state: AppState,
  layoutType: MixedLayoutType,
  section: Section,
  contentType: ContentType,
) => {
  if (getIsMobile(state)) {
    return;
  }

  const layoutName = getLayoutName(layoutType);
  const styleParamKey = getPostLayoutParamByName(layoutName, section, `${contentType}Font`);
  const defaultFontSize = getDefaultFontSize(layoutType, contentType);
  const setFont = getAppSettingsFont(state, styleParamKey, {});

  return setFont.size || defaultFontSize;
};

export const getFontSize = (
  state: AppState,
  layoutType: MixedLayoutType,
  section: Section,
  contentType: ContentType,
) => {
  const stylePath = getPostFontSizesPath(
    layoutType,
    section || SECTION_CATEGORY,
    `${contentType}FontSize`,
  );
  const isMobile = getIsMobile(state);
  const defaultSize = getDefaultFontSize(layoutType, contentType, isMobile);

  if (isMobile) {
    if (section === SECTION_POST_LIST || section === SECTION_RELATED_POSTS) {
      const mobileStylePath = getPostFontSizesPath(
        layoutType,
        section,
        `${contentType}FontSize`,
        true,
      );
      return get(
        getAppSettings(state),
        mobileStylePath,
        get(getAppSettings(state), stylePath, defaultSize),
      );
    }
    const maxFontSize = getMaxFontSize(layoutType, contentType)!;
    const minFontSize = getMinFontSize(layoutType, contentType)!;
    let fallback = getDesktopFontSize(state, section, `${contentType}Font`) || defaultSize;
    fallback = Math.min(maxFontSize, fallback) || fallback;
    fallback = Math.max(minFontSize, fallback) || fallback;
    return get(getAppSettings(state), stylePath, fallback);
  }

  return get(getAppSettings(state), stylePath, defaultSize);
};

/** @deprecated Use `usePostPageShareProviders` instead for Post Page */
export const getPinterestEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_PINTEREST_ENABLED_PATH,
    fallback: false,
  });

/** @deprecated Use `usePostPageShareProviders` instead for Post Page */
export const getTwitterEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_TWITTER_ENABLED_PATH,
    fallback: false,
  });

export const getSBSLayoutImagePosition = (state: AppState, section: Section): SideBySideLayout =>
  getAppSettingsValue({
    state,
    key: getSBSLayoutImagePositionParamPath(section),
    fallback: SideBySideLayout.ImageRight,
  });

export const getIsFeaturedPostBannerEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_BANNER_ENABLED_PATH,
    fallback: IS_BANNER_ENABLED_DEFAULT,
  });

export const getIsCategoryHeaderEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_CATEGORY_HEADER_ENABLED_PATH,
    fallback: false,
  });

export const getIsCreatedWithResponsiveEditor = (state: AppState) =>
  get(getAppSettings(state), 'style.booleans.responsive', false);

const getMyPostsPageBorderWidth = (state: AppState) =>
  getAppSettingsNumber(state, MY_POSTS_PAGE_BORDER_WIDTH, MY_POSTS_PAGE_BORDER_WIDTH_DEFAULT_VALUE);

const PG_SIDE_BY_SIDE_LAYOUT_SIZE = 1100;
const PG_ONE_COLUMN_LAYOUT_SIZE = 980;

const getMyPostsPagePostSize = ({
  state,
  section,
  containerWidth,
  layoutType,
}: {
  state: AppState;
  section: Section;
  containerWidth?: number;
  layoutType: LayoutSettingsType;
}) => {
  const itemSize = getPGLayoutSettingsConstant(layoutType, DEFAULT_PG_LAYOUT_ITEM_SIZE, section);
  const margins = getLayoutMargins({ state, section, layoutType })!;
  const pageBorderWidth = getMyPostsPageBorderWidth(state);
  const availableWidth =
    typeof containerWidth === 'number' ? Math.min(containerWidth, itemSize) : itemSize;

  return availableWidth - pageBorderWidth * 2 - margins * 2;
};

export const getLayoutPostSize = ({
  state,
  section,
  hostWidth,
  rootWidth,
  layoutMargins,
  layoutType,
}: {
  state: AppState;
  section?: Section;
  hostWidth?: number;
  rootWidth?: number;
  layoutMargins?: number;
  layoutType?: LayoutSettingsType;
}) => {
  section ||= getSection(state)!;
  layoutType ||= getLayoutType(state, section) as LayoutSettingsType;
  const layoutName = getLayoutTypeById(layoutType)!;

  const layoutPGSideBySide = isLayoutNamePGSideBySide(layoutName);
  const layoutPGOneColumn = isLayoutNamePGOneColumn(layoutName);
  const width = isNumber(hostWidth) ? hostWidth : isNumber(rootWidth) ? rootWidth : undefined;

  if (section === SECTION_MY_POSTS) {
    return getMyPostsPagePostSize({
      state,
      section,
      containerWidth: width,
      layoutType,
    });
  }

  if (layoutPGSideBySide || layoutPGOneColumn) {
    const layoutSize = layoutPGSideBySide ? PG_SIDE_BY_SIDE_LAYOUT_SIZE : PG_ONE_COLUMN_LAYOUT_SIZE;
    const layoutPostSize = width !== undefined && width < layoutSize ? width : layoutSize;
    const postSize = layoutMargins ? layoutPostSize - layoutMargins * 2 : layoutPostSize;

    return postSize;
  }

  return getAppSettingsValue({
    state,
    key: getLayoutPostSizeParamPath(section, layoutName, getUseMobileLayoutSettings(state)),
    fallback: getPGLayoutSettingsConstant(layoutType, DEFAULT_PG_LAYOUT_ITEM_SIZE, section),
  });
};

const getPGLayoutAppSetting =
  (
    paramPathResolver: (
      section: Section,
      layoutName: LayoutName,
      useMobileLayoutSettings?: boolean,
    ) => `style.numbers.${string}`,
    fallBackSetting?: LayoutSettingsConstantsKey,
    mobileFallBackSetting?: LayoutSettingsConstantsKey,
  ) =>
  ({
    state,
    section,
    layoutType,
  }: {
    state: AppState;
    section?: Section;
    layoutType?: MixedLayoutType;
  }) => {
    section ||= getSection(state)!;
    const useMobileLayoutSettings = getUseMobileLayoutSettings(state);
    layoutType ||= getLayoutType(state, section, useMobileLayoutSettings);

    if (!layoutType) {
      return undefined;
    }

    // @ts-expect-error
    const layoutName = getLayoutTypeById(layoutType)!;

    const key = paramPathResolver(section, layoutName, useMobileLayoutSettings);
    const fallback = getPGLayoutSettingsConstant(
      layoutType as LayoutSettingsType,
      (useMobileLayoutSettings && mobileFallBackSetting) || fallBackSetting!,
      section,
    );

    return getAppSettingsValue({
      state,
      key,
      fallback,
    });
  };

export const getLayoutCardsRowType = getPGLayoutAppSetting(
  getLayoutCardsRowTypeParamPath,
  DEFAULT_PG_LAYOUT_CARDS_ROW_TYPE,
);
export const getLayoutPostsPerRow = getPGLayoutAppSetting(
  getLayoutPostsPerRowParamPath,
  DEFAULT_PG_LAYOUT_POSTS_PER_ROW,
);

export const getLayoutSpacing = getPGLayoutAppSetting(
  getLayoutSpacingParamPath,
  DEFAULT_PG_LAYOUT_SPACING,
  DEFAULT_PG_LAYOUT_SPACING_MOBILE,
);

export const getLayoutImageRatio = getPGLayoutAppSetting(
  getLayoutImageRatioParamPath,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO_MOBILE,
);

export const getLayoutImageAlignment = getPGLayoutAppSetting(
  getLayoutImageAlignmentParamPath,
  DEFAULT_PG_IMAGE_ALIGNMENT,
);

export const getLayoutContentHeight = getPGLayoutAppSetting(
  getLayoutContentHeightParamPath,
  DEFAULT_PG_LAYOUT_INFO_SIZE,
);

export const getLayoutContentHeightType = getPGLayoutAppSetting(
  getLayoutContentHeightTypeParamPath,
  DEFAULT_PG_LAYOUT_INFO_SIZE_TYPE,
);

export const getLayoutMargins = getPGLayoutAppSetting(
  getLayoutMarginsParamPath,
  DEFAULT_PG_LAYOUT_MARGINS,
  DEFAULT_PG_LAYOUT_MARGINS_MOBILE,
);

const getIsConfigurableLineClampingEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_CONFIGURABLE_LINE_CLAMPING_ENABLED_PATH,
    fallback: false,
  });

type PgLayoutAppSettingArgs = Parameters<ReturnType<typeof getPGLayoutAppSetting>>[0];

export const getLayoutTitleLineCount = (props: { state: AppState } & PgLayoutAppSettingArgs) => {
  if (getIsConfigurableLineClampingEnabled(props.state)) {
    return getPGLayoutAppSetting(
      getLayoutTitleLineCountParamPath,
      DEFAULT_PG_LAYOUT_TITLE_LINE_COUNT,
    )(props);
  } else {
    return getPGLayoutAppSetting(getLayoutTitleLineCountParamPath)(props);
  }
};

export const getLayoutDescriptionLineCount = (
  props: { state: AppState } & PgLayoutAppSettingArgs,
) => {
  if (getIsConfigurableLineClampingEnabled(props.state)) {
    return getPGLayoutAppSetting(
      getLayoutDescriptionLineCountParamPath,
      DEFAULT_PG_LAYOUT_DESCRIPTION_LINE_COUNT,
    )(props);
  } else {
    return getPGLayoutAppSetting(getLayoutDescriptionLineCountParamPath)(props);
  }
};

export const getLayoutSidesPadding = getPGLayoutAppSetting(
  getLayoutSidesPaddingParamPath,
  DEFAULT_PG_LAYOUT_SIDES_PADDING,
);

export const getLayoutImageResizingMode = getPGLayoutAppSetting(
  getLayoutImageResizingModeParamPath,
  DEFAULT_PG_IMAGE_RESIZING_MODE,
);

export const getLayoutImageWidth = getPGLayoutAppSetting(
  getLayoutImageWidthParamPath,
  DEFAULT_PG_IMAGE_WIDTH,
);

export const getLayoutImageCropType = getPGLayoutAppSetting(
  getLayoutImageCropTypeParamPath,
  DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE,
);

export const getLayoutImageProportions = getPGLayoutAppSetting(
  getLayoutImageProportionsParamPath,
  DEFAULT_PG_IMAGE_PROPORTIONS,
);

const getPresetColor = (state: AppState, presetName: string) =>
  get(
    find(getColorPresets(state), ({ reference }) => reference === presetName),
    'value',
  );

export function getFeedBorderColor({ state, section }: { state: AppState; section: Section }) {
  const key: `style.colors.${string}` = getUseMobileDesignSettings(state)
    ? getMobileFeedBorderColorPath(section)
    : getFeedBorderColorPath(section);

  const fallback = {
    value: getPresetColor(state, 'color-2')!,
  };

  return getAppSettingsValue({
    state,
    key,
    fallback,
  });
}

export const getIsTagLabelEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_TAG_LABEL_ENABLED_PATH,
    fallback: false,
  });

export const getTagLayout = (state: AppState) => {
  const useMobileSettings = getUseTagMobileSettings(
    state,
    TAG_CLOUD_MOBILE_PARAMS.isMobileLayoutSettingsEnabled.appSettingsPath,
  );

  return getAppSettingsValue({
    state,
    key: useMobileSettings ? TAG_LAYOUT_MOBILE_PATH : TAG_LAYOUT_PATH,
    fallback: TagLayout.Button,
  }) as TagLayout;
};

export const getUseTagMobileSettings = (state: AppState, key: `style.booleans.${string}`) =>
  getIsMobile(state) && getIsMobileSettingEnabled(state, key);

const getTagLayoutParamsByDeviceType = (state: AppState) => {
  const useMobileSettings = getUseTagMobileSettings(
    state,
    TAG_CLOUD_MOBILE_PARAMS.isMobileLayoutSettingsEnabled.appSettingsPath,
  );

  return getTagLayoutParams(getTagLayout(state), useMobileSettings);
};

const getTagDisplayParams = (state: AppState) => {
  const useMobileSettings = getUseTagMobileSettings(
    state,
    TAG_CLOUD_MOBILE_PARAMS.isMobileDisplaySettingsEnabled.appSettingsPath,
  );

  return useMobileSettings ? TAG_DISPLAY_PARAMS_MOBILE : TAG_DISPLAY_PARAMS;
};

export const getTagAlignment = (state: AppState) => {
  const layoutParams = getTagLayoutParamsByDeviceType(state);

  return getAppSettingsValue({
    state,
    key: layoutParams.tagAlignment.appSettingsPath,
    fallback: layoutParams.tagAlignment.defaultAlignment,
  });
};

export const getTagVerticalSpacing = (state: AppState) => {
  const layoutParams = getTagLayoutParamsByDeviceType(state);

  return getAppSettingsValue({
    state,
    key: layoutParams.verticalSpacing.appSettingsPath,
    fallback: layoutParams.verticalSpacing.defaultSpacing,
  });
};

export const getTagHorizontalSpacing = (state: AppState) => {
  const layoutParams = getTagLayoutParamsByDeviceType(state);

  return getAppSettingsValue({
    state,
    key: layoutParams.horizontalSpacing.appSettingsPath,
    fallback: layoutParams.horizontalSpacing.defaultSpacing,
  });
};

export const getNumberOfTags = (state: AppState) => {
  const { numberOfTags } = getTagDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: numberOfTags.appSettingsPath,
    fallback: numberOfTags.defaultValue,
  });
};

export const getTagOrder = (state: AppState) => {
  const { orderTags } = getTagDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: orderTags.appSettingsPath,
    fallback: orderTags.defaultValue,
  }) as TagOrder;
};

export const getTagShowPostCount = (state: AppState) => {
  const { showPostCount } = getTagDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: showPostCount.appSettingsPath,
    fallback: showPostCount.defaultValue,
  });
};

export const getCanSeeMoreButton = createSelector(
  [
    getIsMoreOptionsMenuEnabled,
    getIsMemberAreaInstalled,
    (_, canSee: (action: PostAction, type: 'post', post: NormalizedPost) => boolean) => canSee,
  ],
  (isMoreOptionsMenuEnabled, isMemberAreaInstalled, canSee) => (post: NormalizedPost) => {
    if (!isMoreOptionsMenuEnabled) {
      return false;
    } else {
      const postActions = getPostActions({
        post,
        canSee,
        enableShare: true,
        enableSubscribe: isMemberAreaInstalled,
      });
      return Boolean(postActions.length);
    }
  },
);

export const getIsCategoryLabelEnabled = (state: AppState) =>
  getDisplayToggle({
    state,
    key: IS_CATEGORY_LABEL_ENABLED_PATH,
    mobileKey: IS_CATEGORY_LABEL_ENABLED_MOBILE_PATH,
    wixParam: IS_CATEGORY_LABEL_ENABLED,
  }) as boolean;

export const getLiveSiteUrl = (state: AppState) => state.topology?.baseUrl;

export const getContentAlignment = (state: AppState, isRTL: boolean) =>
  getAppSettingsValue({
    state,
    key: `style.numbers.${feedWidgetStyleParams.contentAlignment.key}`,
    fallback: isRTL ? ContentAlignment.Right : ContentAlignment.Left,
  }) as ContentAlignment;

export const getMobileContentAlignment = (state: AppState, isRTL: boolean) =>
  getAppSettingsValue({
    state,
    key: `style.numbers.${feedWidgetStyleParams.contentAlignmentMobile.key}`,
    fallback: isRTL ? ContentAlignment.Right : ContentAlignment.Left,
  }) as ContentAlignment;

export const getIsPostDesignInFullPostEnabled = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: IS_POST_DESIGN_IN_FULL_POST_ENABLED_PATH,
    fallback: false,
  });
