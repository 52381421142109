import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { get, isNumber } from 'lodash';
import { type Options } from 'pro-gallery-lib';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  isLayoutNameList,
  isLayoutNamePGSideBySide,
  isLayoutNameTextOnImage,
  type LayoutName,
  type PostAction,
  type Section,
} from '@wix/communities-blog-client-common';
import { TEXT_PLACEMENT, type TextBoxAlignment } from '../../constants/pro-gallery-options';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import usePostFontSize from '../../hooks/use-post-font-size';
import { getContentAlignment } from '../../selectors/app-settings-selectors';
import { getPostByIdOrSlug, getPostCoverImageSrc } from '../../selectors/post-selectors';
import { getContentAlignmentStyles } from '../../services/content-alignment-helpers';
import { getFeedColorClassName, type LayoutItemConfig } from '../../services/layout-config';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import { type NormalizedPost } from '../../types';
import { PostFooter } from '../post-footer';
import { useSelector } from '../runtime-context';
import { HorizontalSeparatorForPostCard } from '../separator';
import { PostListItemProGalleryCategoryLabel } from './post-list-item-pro-gallery-category-label';
import { PostListItemProGalleryHeader } from './post-list-item-pro-gallery-header';
import { PostListItemProGalleryHeaderTitleContainer } from './post-list-item-pro-gallery-header-title-container';
import { PostListItemProGalleryRatingsDisplay } from './post-list-item-pro-gallery-ratings-display';

const empty = {};

type PostListItemProGalleryProps = {
  itemConfig: Partial<LayoutItemConfig>;
  type: LayoutName | undefined;
  postId: string | undefined;
  post: NormalizedPost | undefined;
  galleryOptions: Options;
  layoutSidesPadding: number;
  section: Section;
  canSeeMoreButton?: (post: NormalizedPost) => boolean;
  textBoxAlignment: TextBoxAlignment;
  visibleActions: PostAction[] | undefined;
};

const PostListItemProGallery: React.FC<PostListItemProGalleryProps> = React.memo(
  ({
    type,
    post: unsafePost,
    postId,
    itemConfig,
    canSeeMoreButton = () => false,
    layoutSidesPadding,
    galleryOptions = {},
    textBoxAlignment,
    visibleActions,
  }) => {
    const { isRTL } = useEnvironment();
    const { applyFeedDesign, getPostClassName, section } = useIsFeedDesignEnabled();
    const post = useSelector((state) => unsafePost || getPostByIdOrSlug(state, postId)!);
    const { contentFontClassName } = useFontClassName();
    const { postMetadataFontSize } = usePostFontSize();
    const feedMetadataSettings = useFeedMetadataSettings({ post });
    const alignment = useSelector((state) => {
      const contentAlignment = getContentAlignment(state, isRTL);
      return getContentAlignmentStyles(contentAlignment, isRTL);
    });
    const showMoreButton = canSeeMoreButton(post);
    const { displayFooterIcons } = itemConfig;
    const layoutList = isLayoutNameList(type);
    const isSideBySideLayout = isLayoutNamePGSideBySide(type);
    const withoutFooter = !feedMetadataSettings.isMetadataFooterVisible;
    const layoutTypeClassName = type ? styles[type] : undefined;
    const containerClassName = useMemo(
      () =>
        classNames(
          styles.container,
          styles.proGallery,
          isRTL && styles.isRTL,
          layoutTypeClassName,
          alignmentStyles[alignment],
          withoutFooter && styles.withoutFooter,
          contentFontClassName,
          'blog-text-color',
          'blog-card-background-color',
          'blog-card-border-color',
          'post-list-item',
          getPostClassName(
            'border-color',
            'post-container',
            getFeedColorClassName(type, 'background-color'),
          ),
        ),
      [
        isRTL,
        layoutTypeClassName,
        alignment,
        withoutFooter,
        contentFontClassName,
        getPostClassName,
        type,
      ],
    );

    const contentWrapperClassName = useMemo(
      () =>
        classNames(
          styles.contentWrapper,
          layoutTypeClassName,
          isRTL && alignmentStyles.isRTL,
          !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
          withoutFooter && styles.withoutFooter,
          'post-list-item-wrapper',
          isLayoutNameTextOnImage(type) && getPostClassName('overlay-background-color'),
          getPostClassName(
            'description-font',
            getFeedColorClassName(type, 'description-color'),
            getFeedColorClassName(type, 'description-fill'),
          ),
        ),
      [isRTL, layoutTypeClassName, applyFeedDesign, withoutFooter, getPostClassName, type],
    );

    const footerClassName = useMemo(
      () =>
        classNames(
          getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
        ),
      [getPostClassName, type],
    );

    const styleFontSizeMetadata = useMemo(
      () => ({
        fontSize: postMetadataFontSize,
      }),
      [postMetadataFontSize],
    );

    const styleContainer = useMemo(() => {
      const style: React.CSSProperties = {
        background:
          (getPostCoverImageSrc(post) || post?.media?.embedMedia?.thumbnail?.url) &&
          isLayoutNameTextOnImage(type)
            ? 'transparent'
            : undefined,
        borderWidth: 0,
        '--wix-blog-inline-padding': isNumber(layoutSidesPadding)
          ? `${layoutSidesPadding}px`
          : undefined,
      };

      if (layoutList) {
        style.background = 'transparent';
        style.paddingTop = '12px';
        style.paddingBottom = '12px';

        if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT) {
          style.marginLeft = '14px';
        } else {
          style.marginRight = '14px';
        }
      }

      // HACK: for side-by-side with borders and no image
      if (!get(post, 'coverImage.shouldRender') && isSideBySideLayout) {
        if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT) {
          style.paddingLeft = `${galleryOptions.itemBorderWidth * 2}px`;
        }
        if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_LEFT) {
          style.paddingRight = `${galleryOptions.itemBorderWidth * 2}px`;
        }
      }

      return style;
    }, [
      post,
      type,
      layoutSidesPadding,
      layoutList,
      textBoxAlignment,
      galleryOptions.itemBorderWidth,
      isSideBySideLayout,
    ]);

    const getLayoutPaddingStyles = useCallback(
      (isFooter = false): React.CSSProperties => {
        if (!isLayoutNameTextOnImage(type) && isFooter) {
          return empty;
        }

        return isNumber(layoutSidesPadding)
          ? {
              paddingLeft: layoutSidesPadding,
              paddingRight: layoutSidesPadding,
              ...(isFooter && {
                left: 0,
                right: 0,
              }),
            }
          : {};
      },
      [layoutSidesPadding, type],
    );

    return (
      <div
        className={classNames(containerClassName, styles.forceRelative)}
        style={styleContainer}
        data-hook="post-list-item"
      >
        <div style={getLayoutPaddingStyles()} className={contentWrapperClassName}>
          <PostListItemProGalleryHeaderTitleContainer
            post={post}
            showMoreButton={showMoreButton}
            layoutList={layoutList}
            type={type}
            styleFontSizeMetadata={styleFontSizeMetadata}
            visibleActions={visibleActions}
            withoutFooter={withoutFooter}
            itemConfig={itemConfig}
          />
          {layoutList ? (
            <>
              <PostListItemProGalleryCategoryLabel post={post} type={type} />
              <PostListItemProGalleryRatingsDisplay
                post={post}
                type={type}
                withoutFooter={withoutFooter}
                section={section}
              />
              <PostListItemProGalleryHeader
                post={post}
                type={type}
                styleFontSizeMetadata={styleFontSizeMetadata}
                showMoreButton={showMoreButton}
                visibleActions={visibleActions}
              />
            </>
          ) : null}
          {feedMetadataSettings.isMetadataFooterVisible && (
            <div
              style={getLayoutPaddingStyles(true)}
              className={classNames(
                styles.footer,
                layoutTypeClassName,
                styles.withoutCover,
                styles[section],
              )}
            >
              {!layoutList && (
                <HorizontalSeparatorForPostCard
                  className={classNames(styles.separator, layoutTypeClassName)}
                />
              )}
              <div style={styleFontSizeMetadata}>
                <PostFooter
                  className={footerClassName}
                  displayIcons={displayFooterIcons}
                  post={post}
                  type={type}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default PostListItemProGallery;
