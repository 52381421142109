import { get } from 'lodash';
import {
  getBundleName,
  getLayoutTypePath,
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_FULL_POST,
  LAYOUT_ONE_COLUMN_MOBILE,
  LAYOUT_PG_GRID,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
  SECTION_MY_POSTS,
  type Section,
  type MixedLayoutType,
} from '@wix/communities-blog-client-common';
import { getMyPostsLayoutType } from '../../my-posts-page/store/selectors/my-posts-layout-type';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../../post-list-widget/constants/post-list-widget';
import { getPostListLayoutType } from '../../post-list-widget/selectors/post-list-layout-type';
import { RELATED_POSTS_WIDGET_BUNDLE_NAME } from '../../related-posts-widget/constants/related-posts-widget';
import { getRelatedPostsLayoutType } from '../../related-posts-widget/selectors/related-posts-selectors';
import { isSeo, getIsMobile, getIsDesktop } from '../store/basic-params/basic-params-selectors';
import { type AppState } from '../types';
import { getAppSettings } from './app-settings-base-selectors';
import { getIsCreatedWithResponsiveEditor } from './app-settings-selectors';
import { getSection } from './section-selectors';

// use withLayoutProps HOC for app

export const getDefaultLayout = (state: AppState, isMobile?: boolean) => {
  if (isMobile) {
    return LAYOUT_ONE_COLUMN_MOBILE;
  }

  if (
    getBundleName(state) === POST_LIST_WIDGET_BUNDLE_NAME ||
    getBundleName(state) === RELATED_POSTS_WIDGET_BUNDLE_NAME
  ) {
    return LAYOUT_PG_GRID;
  }

  const isCreatedWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);
  return isCreatedWithResponsiveEditor ? LAYOUT_PG_GRID : LAYOUT_SIDE_BY_SIDE;
};

export function getLayoutType(
  state: AppState,
  section: Section,
  isMobile?: boolean,
): MixedLayoutType;
export function getLayoutType(
  state: AppState,
  section: Section | undefined,
  isMobile?: boolean,
): MixedLayoutType | undefined;
export function getLayoutType(
  state: AppState,
  section?: Section,
  isMobile = getIsMobile(state),
): MixedLayoutType | undefined {
  if (!section) {
    return undefined;
  }

  if (section === SECTION_POST_LIST) {
    return getPostListLayoutType({ state, isMobile });
  }

  if (section === SECTION_RELATED_POSTS) {
    return getRelatedPostsLayoutType({ state, isMobile });
  }

  if (section === SECTION_MY_POSTS) {
    return getMyPostsLayoutType(state);
  }

  const defaultLayout = getDefaultLayout(state, isMobile);
  return get(
    getAppSettings(state),
    getLayoutTypePath(section, isMobile),
    defaultLayout,
  ) as MixedLayoutType;
}

export const isMasonryLayoutSupported = (state: AppState) => !isSeo(state);

export const isFullPostLayout = (state: AppState, section?: Section) =>
  getLayoutType(state, section || getSection(state, true)) === LAYOUT_FULL_POST;

export const isPostContentRequired = (state: AppState, section?: Section) =>
  getIsDesktop(state) && isFullPostLayout(state, section);

export const isExcludePostContentSupported = (state: AppState, section?: Section) =>
  !isPostContentRequired(state, section);
